<template>
    <div>

        <!-- <div class="d-flex text-white justify-content-end align-items-center px-2 pt-2  h4" style="gap:10px">
            Filter Data <i class="bx bxs-filter-alt" style="color: #FFAD32;"></i>
        </div> -->
 
        <h2 class="text-center text-white  mt-3">Mongrela Associated Vet</h2>
        <!-- <p class="px-2 text-white text-justify mt-1">
            Join us in enhancing the lives of our little friends with the support of our community of vets and clinics. Your participation is crucial in providing our furry companions with an improved and healthier life. Be a part of our mission to make a positive impact on the well-being of our beloved pets.        
        </p> -->

        <hr style="border-color: #FFAD32;" />

        <div v-for="item in vet">
            <router-link :to="`/vet/profile/${item.vet_id}`" class="d-flex align-items-center px-2 pt-1" style="gap:10px 20px">
                <div>
                    <img :src="item.vet_picture"
                        class="rounded-circle" style="height:80px;width:80px" />
                </div>
                <div class="flex-fill">
                    <h3 class="text-white h4 mb-0">{{item.vet_name}}</h3>
                    <h3 class="text-white h6 mb-0 d-flex align-items-center" style="margin: .25em 0 !important;font-weight: normal;gap:5px">
                        <i class="bx bxs-map" style="font-size: 18px;"></i> {{ item.vet_location }}    
                    </h3>
                </div>
                <!-- <div>
                    <h3 class="text-white h6 mb-0 text-right" style="">4.5 <i class="bx bxs-star" style="color:#FFAD32"></i></h3>
                </div> -->
            </router-link>
        </div>

    </div>
</template>

<script>

import store from '@/store'

export default {
    computed: {
        user(){
            return store.state.auth.USER
        },
        vet(){
            return store.state.vet.VET_LIST
        }
    },
    mounted(){
        store.dispatch('vet/GetVetList')
    },
    data(){
        return {
        }
    }
}

</script>